import { template as template_2792e6e059ca4c1fb19f62754cf47609 } from "@ember/template-compiler";
const FKLabel = template_2792e6e059ca4c1fb19f62754cf47609(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
