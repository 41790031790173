import { template as template_1423b1c2dac2417fa55c6237e3d46d06 } from "@ember/template-compiler";
const WelcomeHeader = template_1423b1c2dac2417fa55c6237e3d46d06(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
