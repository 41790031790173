import { template as template_ce694e55eb864e18a095cca5c140be89 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_ce694e55eb864e18a095cca5c140be89(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
