import { template as template_9bd1d4f43702408d8a23aa2fbec77b2a } from "@ember/template-compiler";
const FKControlMenuContainer = template_9bd1d4f43702408d8a23aa2fbec77b2a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
