import { template as template_ff20d9ba49d949cc91e59d97a0bfc5fb } from "@ember/template-compiler";
const FKText = template_ff20d9ba49d949cc91e59d97a0bfc5fb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
